<template>
  <v-container class="pa-0 app-fill-height mobile-container">
    <v-container tag="section" class="pa-0 mb-10 mobile-container">
      <v-responsive        
        max-width="800px"
        class="mx-auto"
      >
          <v-card class="page-box-wrapper elevation-3">
            <div class="page-box-content">
              <h2 class="h2-green">LR Zeitgard Signature</h2>
              <h3 class="h3-main">Макияж, который ухаживает за кожей</h3>
              <div class="img-top-box">
                <img
                  src="/img/made-in-germany.png"
                  alt="Made in Germany"
                  class="germany-top"
                />
                <div class="text-center">
                  <img
                    src="/img/signature/sig-main.jpg"
                    alt="Набор LR Zeitgard Signature"
                    class="sig-main"
                  />
                </div>
              </div>
              <div class="p-main">
                <h3 class="h3-main">Одна косметичка - множество образов</h3>
                <p class="mt-3">
                  <strong>Все, что нужно для идеального макияжа:</strong>
                  создавайте самые разные образы под любое настроение с помощью
                  средств Signature для лица, глаз и губ.
                </p>
                <p class="mt-3">
                  <strong>В одной косметичке</strong> собраны все самые
                  необходимые декоративные средства высокого качества, которые
                  идеально сочетаются друг с другом по текстуре и цвету.
                </p>
                <p class="mt-3">
                  <strong>Легко использовать</strong> — легко создавать
                  идеальные образы, как от профессиональных визажистов.
                </p>
                <div class="text-center mt-5">
                  <div
                    class="wistia_responsive_padding"
                    style="padding: 56.25% 0 0 0; position: relative"
                  >
                    <div
                      class="wistia_responsive_wrapper"
                      style="
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                      "
                    >
                      <iframe
                        src="https://fast.wistia.net/embed/iframe/7t81706wxc?seo=false&videoFoam=true"
                        title="Промо-ролик Video"
                        allow="autoplay; fullscreen"
                        allowtransparency="true"
                        frameborder="0"
                        scrolling="no"
                        class="wistia_embed"
                        name="wistia_embed"
                        msallowfullscreen
                        width="100%"
                        height="100%"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <div class="col-text">
                        <div class="text-center">
                          <img
                            src="/img/signature/experts-icon.png"
                            alt=""
                            class="img-icon"
                          />
                        </div>
                        <h3 class="h3-col-title">
                          РАЗРАБОТАНО ЭКСПЕРТАМИ В ОБЛАСТИ КРАСОТЫ
                        </h3>
                        <p class="p-col">
                          Новая линия косметики LR ZEITGARD Signature была
                          разработана в тесном сотрудничестве с
                          профессиональными визажистами.
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div class="col-text">
                        <div class="text-center">
                          <img
                            src="/img/signature/uhod-icon.png"
                            alt=""
                            class="img-icon"
                          />
                        </div>
                        <h3 class="h3-col-title">
                          УДИВИТЕЛЬНО КРАСИВАЯ И СИЯЮЩАЯ КОЖА
                        </h3>
                        <p class="p-col">
                          Секрет идеального макияжа — качественный уход за
                          кожей. Очищение и подготовка кожи лица — важнейшее
                          условие создания идеальной основы для сияющего
                          макияжа.
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <div class="col-text">
                        <div class="text-center">
                          <img
                            src="/img/signature/premium-icon.png"
                            alt=""
                            class="img-icon"
                          />
                        </div>
                        <h3 class="h3-col-title">
                          ИННОВАЦИОННАЯ КОСМЕТИКА ПРЕМИУМ-КЛАССА
                        </h3>
                        <p class="p-col">
                          Вся продукция соответствует самым высоким стандартам
                          качества. Мы работаем только с инновационными
                          технологиями и текстурами, которые производятся в
                          Европе и проходят дерматологическое тестирование
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div class="col-text">
                        <div class="text-center">
                          <img
                            src="/img/signature/experts-icon.png"
                            alt=""
                            class="img-icon"
                          />
                        </div>
                        <h3 class="h3-col-title">
                          РОСКОШНЫЕ СРЕДСТВА ДЛЯ ЖЕНЩИН ЛЮБОГО ВОЗРАСТА
                        </h3>
                        <p class="p-col">
                          Линия косметики премиум- класса, разработанная
                          специально для LR, способна подчеркнуть естественную
                          красоту женщин любого возраста
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">
                    Макияж, который ухаживает за кожей
                  </h2>
                  <div class="text-center">
                    <img
                      src="/img/signature/oil-sig.jpg"
                      alt=""
                      class="oil-sig"
                    />
                  </div>
                  <p class="mt-5">
                    Косметика LR ZEITGARD Signature не только позволяет создать
                    прекрасный сияющий образ, но и обеспечивает коже
                    дополнительный уход.
                  </p>
                  <p class="mt-3">
                    Макияж должен не только визуально улучшать внешний вид вашей
                    кожи, но и ухаживать за ней. Именно поэтому в составе
                    декоративных средств присутствуют тщательно отобранные
                    ингредиенты, которые ухаживают за кожей и придают ей
                    сияющий, здоровый вид.
                  </p>
                  <p class="mt-3">
                    Средства для макияжа LR ZEITGARD Signature, которые
                    позволяют создавать эффектные образы и при этом ухаживают за
                    кожей, — это идеальный способ подчеркнуть вашу естественную
                    красоту, над которой не властно время.
                  </p>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Для создания тона</h2>
                  <v-row class="mt-5">
                    <v-col cols="12" sm="4" class="text-center">
                      <img
                        src="/img/signature/ton-face.jpg"
                        alt=""
                        class="face-avatar"
                      />
                    </v-col>
                    <v-col cols="12" sm="8">
                      <p>
                        Сияющая тональная основа — это идеальная база для вашего
                        уникального макияжа. Безупречная, сияющая кожа благодаря
                        натуральному блюр-эффекту.
                      </p>
                      <p class="mt-3">
                        Средство с легкой текстурой практически не ощущается на
                        коже, и его можно наносить в несколько слоев для более
                        плотного покрытия. А входящие в состав тщательно
                        отобранные ухаживающие ингредиенты увлажняют и
                        успокаивают кожу.
                      </p>
                      <p class="mt-3">
                        Тональная основа имеет фактор SPF 20 для оптимальной
                        защиты кожи в течение дня.
                      </p>
                    </v-col>
                  </v-row>
                  <p class="mt-3 mb-7">
                    Наши тональные средства подходят для всех тонов кожи —
                    теплых, холодных и нейтральных. Они идеально подстроятся под
                    оттенок вашей кожи, чтобы подчеркнуть вашу уникальную
                    красоту.
                  </p>
                  <v-row class="">
                    <v-col cols="12" sm="4" class="col-img-center">
                      <img
                        src="/img/signature/ton-sig.jpg"
                        alt=""
                        class="col-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="8" class="">
                      <h4>Сияющая тональная основа</h4>
                      <ul class="ul-col">
                        <li>Идеальное покрытие без эффекта маски</li>
                        <li>Естественное сияние и свежий цвета лица</li>
                        <li>Увлажняет и успокаивает кожу</li>
                        <li>SPF 20</li>
                      </ul>
                      <ul class="ul-col-w mt-3">
                        <li><strong>29022-1</strong> Очень светлый | 32 мл</li>
                        <li><strong>29022-2</strong> Светлый | 32 мл</li>
                        <li><strong>29022-3</strong> Нейтральный | 32 мл</li>
                      </ul>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12" sm="4" class="col-img-center">
                      <img
                        src="/img/signature/kenturing.jpg"
                        alt=""
                        class="col-img-v"
                      />
                    </v-col>
                    <v-col cols="12" sm="8" class="">
                      <h4>Контуринг Кремовый бронзер</h4>
                      <ul class="ul-col">
                        <li>Идеально четкие контуры лица</li>
                        <li>Придает коже свежий вид</li>
                        <li>Мягкая, бархатистая текстура</li>
                        <li>Возможность наслаивания</li>
                      </ul>
                      <ul class="ul-col-w mt-3">
                        <li><strong>29025</strong> | 10 мл</li>
                      </ul>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12" sm="4" class="col-img-center">
                      <img
                        src="/img/signature/rumyana.jpg"
                        alt=""
                        class="col-img-v"
                      />
                    </v-col>
                    <v-col cols="12" sm="8" class="">
                      <h4>Шелковистые Кремовые румяна</h4>
                      <ul class="ul-col">
                        <li>Придают коже свежий, яркий оттенок</li>
                        <li>Создают эффект отдохнувшего лица</li>
                        <li>Мягкая, бархатистая текстура</li>
                        <li>Возможность наслаивания</li>
                      </ul>
                      <ul class="ul-col-w mt-3">
                        <li><strong>29026</strong> | 10 мл</li>
                      </ul>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12" sm="4" class="col-img-center">
                      <img
                        src="/img/signature/highlighter.jpg"
                        alt=""
                        class="col-img-v"
                      />
                    </v-col>
                    <v-col cols="12" sm="8" class="">
                      <h4>Кремовый хайлайтер</h4>
                      <ul class="ul-col">
                        <li>Естественный эффект перламутрового свечения</li>
                        <li>Легкая комфортная текстура</li>
                        <li>Множество способов применения</li>
                      </ul>
                      <ul class="ul-col-w mt-3">
                        <li><strong>29024</strong> | 10 мл</li>
                      </ul>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12" sm="4" class="col-img-center">
                      <img
                        src="/img/signature/pudra.jpg"
                        alt=""
                        class="col-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="8" class="">
                      <h4>Фиксирующая пудра</h4>
                      <ul class="ul-col">
                        <li>Стойкое покрытие и натуральный матовый финиш</li>
                        <li>Мягкая, шелковистая текстура</li>
                        <li>Позволяет коже дышать</li>
                      </ul>
                      <ul class="ul-col-w mt-3">
                        <li><strong>29023</strong> | 9 г</li>
                      </ul>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Макияж глаз</h2>
                  <div class="text-center mt-3 mb-3">
                    <img
                      src="/img/signature/eyes-face.jpg"
                      alt=""
                      class="face-avatar-abs"
                    />
                  </div>
                  <v-row class="">
                    <v-col cols="12" sm="4" class="col-img-center">
                      <img
                        src="/img/signature/gel-brovi.jpg"
                        alt=""
                        class="col-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="8" class="">
                      <h4>Фиксирующий гель для бровей</h4>
                      <ul class="ul-col">
                        <li>Идеальная форма и четкость</li>
                        <li>Натуральный эффект и безупречная стойкость</li>
                        <li>Точное и легкое нанесение без склеивания</li>
                        <li>Брови выглядят более густыми</li>
                      </ul>
                      <ul class="ul-col-w mt-3">
                        <li><strong>29030-1</strong> Светлый | 3,5 мл</li>
                        <li><strong>29030-2</strong> Тёмный | 3,5 мл</li>
                      </ul>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12" sm="4" class="col-img-center">
                      <img
                        src="/img/signature/tush.jpg"
                        alt=""
                        class="col-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="8" class="">
                      <h4>Тушь для ресниц «Интенсивный эффект»</h4>
                      <ul class="ul-col">
                        <li>Водостойкая тушь, которая держится до 24 часов</li>
                        <li>Точное и легкое нанесение без склеивания</li>
                        <li>Ухаживает за ресницами и укрепляет их</li>
                      </ul>
                      <ul class="ul-col-w mt-3">
                        <li><strong>29029</strong> | 8 мл</li>
                      </ul>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12" sm="4" class="col-img-center">
                      <img
                        src="/img/signature/serum.jpg"
                        alt=""
                        class="col-img-vs"
                      />
                    </v-col>
                    <v-col cols="12" sm="8" class="">
                      <h4>Активирующая Сыворотка для ресниц</h4>
                      <ul class="ul-col">
                        <li>Стимулирует рост ресниц</li>
                        <li>Сокращает выпадение ресниц</li>
                        <li>Укрепляет ресницы</li>
                        <li>Без гормонов</li>
                      </ul>
                      <ul class="ul-col-w mt-3">
                        <li><strong>29027</strong> | 5.5 мл</li>
                      </ul>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12" sm="4" class="col-img-center">
                      <img
                        src="/img/signature/teni.jpg"
                        alt=""
                        class="col-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="8" class="">
                      <h4>Тени для век «ИДЕАЛЬНАЯ КОЛЛЕКЦИЯ»</h4>
                      <ul class="ul-col">
                        <li>Плотность покрытия от средней до высокой</li>
                        <li>
                          Мягкая кремовая текстура для равномерного нанесения
                        </li>
                        <li>Высокая стойкость, не скатываются на веках</li>
                      </ul>
                      <p class="mt-3">
                        <strong>Матовый кремово-розовый</strong><br>
                        <strong>Розовый металлик</strong><br>
                        <strong>Медный металлик</strong><br>
                        <strong>Матовый терракотовый</strong><br>
                        <strong>Бургунди металлик</strong><br>
                        <strong>Сияющий коричневый</strong><br>
                      </p>
                      <ul class="ul-col-w mt-3">
                        <li><strong>29028</strong> | 6.5 г</li>
                      </ul>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Макияж губ</h2>
                  <div class="text-center mt-3 mb-3">
                    <img
                      src="/img/signature/lips-face.jpg"
                      alt=""
                      class="face-avatar-abs"
                    />
                  </div>
                  <v-row class="">
                    <v-col cols="12" sm="4" class="col-img-center">
                      <img
                        src="/img/signature/pencil-lips.jpg"
                        alt=""
                        class="col-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="8" class="">
                      <h4>Мягкий карандаш для губ</h4>
                      <ul class="ul-col">
                        <li>Точное нанесение и четкий контур губ</li>
                        <li>Насыщенные стойкие цвета</li>
                        <li>Практически не ощущается на коже</li>
                      </ul>
                      <ul class="ul-col-w mt-3">
                        <li><strong>29031-1</strong> Красный | 2 г</li>
                        <li><strong>29031-2</strong> Розовый нюд | 2 г</li>
                        <li><strong>29031-3</strong> Ягодный розовый | 2 г</li>
                      </ul>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12" sm="4" class="col-img-center">
                      <img
                        src="/img/signature/blesk-lips.jpg"
                        alt=""
                        class="col-img-v"
                      />
                    </v-col>
                    <v-col cols="12" sm="8" class="">
                      <h4>Придающий объем блеск для губ</h4>
                      <ul class="ul-col">
                        <li>Мгновенный и стойкий эффект объема</li>
                        <li>Ультраглянцевое покрытие</li>
                        <li>Стимулирует выработку коллагена*</li>
                        <li>Увлажняет и питает, помогает при сухости и трещинках*</li>
                      </ul>
                      <p class="mt-3">* Применять 3 раза в день в течение как минимум 28 дней</p>
                      <ul class="ul-col-w mt-3">
                        <li><strong>29033</strong> Красный | 5 мл</li>
                      </ul>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12" sm="4" class="col-img-center">
                      <img
                        src="/img/signature/pomada.jpg"
                        alt=""
                        class="col-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="8" class="">
                      <h4>Матовая шелковистая губная помада</h4>
                      <ul class="ul-col">
                        <li>Шелковистая текстура и матовое покрытие</li>
                        <li>Насыщенный цвет</li>
                        <li>Ухаживает за губами</li>
                        <li>Практически не ощущается на губах</li>
                      </ul>
                      <ul class="ul-col-w mt-3">
                        <li><strong>29032-1</strong> Красный | 4 г</li>
                        <li><strong>29032-2</strong> Розовый нюд | 4 г</li>
                        <li><strong>29032-3</strong> Ягодный розовый | 4 г</li>
                      </ul>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Образ "Сияющая кожа"</h2>
                  <v-row class="mt-5">
                    <v-col cols="12" sm="4" class="text-center">
                      <img
                        src="/img/signature/shine-skin-face.jpg"
                        alt=""
                        class="face-avatar"
                      />
                    </v-col>
                    <v-col cols="12" sm="8">
                      <p>
                        Хотите подчеркнуть свою естественную красоту?
                      </p>
                      <p class="mt-3">
                        Создайте идеальный образ в стиле «Сияющая кожа». 
                      </p>
                      <p class="mt-3">
                        Этот легкий макияж на каждый день или для особого случая подчеркнет естественное сияние кожи. Он придает лицу здоровый, свежий вид и делает образ выразительным.
                      </p>
                    </v-col>
                  </v-row>
                  <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/5gkv8zy40b?seo=false&videoFoam=true" title="Сияющая кожа Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Образ "Пленительный взгляд"</h2>
                  <v-row class="mt-5">
                    <v-col cols="12" sm="4" class="text-center">
                      <img
                        src="/img/signature/vzglyad-face.jpg"
                        alt=""
                        class="face-avatar"
                      />
                    </v-col>
                    <v-col cols="12" sm="8">
                      <p>
                        Взгляд может сказать больше, чем тысяча слов — расскажите свою
историю с помощью макияжа «Пленительный взгляд», который
подчеркнет красоту ваших глаз и придаст вашему взгляду глубину и
выразительность.
                      </p>
                      <p class="mt-3">
                        Уложите брови и нанесите блеск для губ в качестве финального
штриха — и вы создадите образ уверенной в себе женщины, которая
не останется незамеченной!
                      </p>
                    </v-col>
                  </v-row>                  
                  <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/dcslat60ut?seo=false&videoFoam=true" title="Пленительный взгляд Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Глаза: образ "День/Ночь"</h2>
                  <v-row class="mt-5">
                    <v-col cols="12" sm="4" class="text-center">
                      <img
                        src="/img/signature/day-night-skin.jpg"
                        alt=""
                        class="face-avatar"
                      />
                    </v-col>
                    <v-col cols="12" sm="8">
                      <p>
                        У вас есть планы на вечер, но нет времени на полную смену макияжа
после насыщенного рабочего дня?
                      </p>
                      <p class="mt-3">
                        Вы сможете легко превратить свой дневной макияж в роскошный
вечерний! Шесть универсальных оттенков теней для век и три
выразительных оттенка для губ — LR ZEITGARD Signature предлагает
вам полную палитру от деликатных до насыщенных оттенков для
любого настроения и случая
                      </p>
                    </v-col>
                  </v-row>                  
                  <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/41pg34dul7?seo=false&videoFoam=true" title="Глаза День-ночь Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Образ "Красные губы"</h2>
                  <v-row class="mt-5">
                    <v-col cols="12" sm="4" class="text-center">
                      <img
                        src="/img/signature/red-lips-face.jpg"
                        alt=""
                        class="face-avatar"
                      />
                    </v-col>
                    <v-col cols="12" sm="8">
                      <p>
                        Привлекающий внимание образ, в котором акцент сделан на
роскошные губы! 
                      </p>
                      <p class="mt-3">
                        Техника макияжа «Красные губы» позволит вам
легко и быстро создать эффект объемных, четко очерченных губ.
Такой эффектный образ с акцентом на яркие губы, дополненный
деликатным макияжем глаз, придаст вам уверенности в себе и
сделает вас настоящей звездой любого события!
                      </p>
                    </v-col>
                  </v-row>                  
                  <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/1ckvo9ayps?seo=false&videoFoam=true" title="Красные губы Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Губы: образ «День/Ночь»</h2>                      
                  <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/275xmygigh?seo=false&videoFoam=true" title="Губы День-ночь Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
                </div>
              </div>
              <div class="text-center mt-10" id="contacts">
                <h3 class="h3-contacts">
                  За подробной консультацией по набору напишите мне
                </h3>
                <v-icon
                  icon="mdi-chevron-triple-down"
                  size="x-large"
                  color="#94b894"
                  class="mt-5"
                ></v-icon>
                <div class="mt-12 mb-7">
                  <div class="d-flex grow flex-wrap">
                        <div
                            class="contact-avatar v-avatar mx-auto v-card--material__avatar elevation-6 grey"
                            style="height: 128px; min-width: 128px; width: 128px"
                        >
                            <div class="v-image v-responsive theme--light">
                                <img v-if="userData.avatar"
                                     :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                     alt=""
                                >
                                <div class="v-responsive__content" style="width: 250px"></div>
                            </div>
                        </div>

                        <v-card-text class="text-center">
                            <h4 class="display-2 font-weight-light mb-3 black--text">
                                {{ userData.name }} {{ userData.last_name }}
                            </h4>
                        </v-card-text>
                    </div>
                    <v-row>
                        <v-col class="text-center">
                            <div class="input-group mb-3">
                                <v-tooltip
                                    top
                                    v-if="userData.phone_whatsapp"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            style="text-decoration: none"
                                            :href="`https://wa.me/${userData.phone_whatsapp}`"
                                            target="_blank"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="success"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-whatsapp</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в WhatsApp</span>
                                </v-tooltip>

                                <v-tooltip
                                    top
                                    v-if="userData.telegram"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            style="text-decoration: none"
                                            :href="`${userData.telegram}`"
                                            target="_blank"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="blue"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>fab fa-telegram-plane</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в Telegram</span>
                                </v-tooltip>
                            </div>
                        </v-col>
                    </v-row>
                </div>
              </div>
            </div>
          </v-card>
      </v-responsive>
    </v-container>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "Signature",
    data: () => ({
        cookieShow: true,
    }),
    metaInfo: {
        title: 'Набор LR Zeitgard Signature',
        meta: [
            { vmid: 'description', property: 'description', content: 'Макияж, который ухаживает за кожей' },
            { vmid: 'og:title', property: 'og:title', content: 'Набор LR Zeitgard Signature' },
            { vmid: 'og:description', property: 'og:description', content: 'Макияж, который ухаживает за кожей' },
            { vmid: 'og:image', property: 'og:image', content: '' },
        ],
    },
    created() {
        if (!this.$route.query.p) {
            if (this.$cookies.isKey('lifeplus_partner')) {
                const partnerNum = this.$cookies.get('lifeplus_partner')
                this.$router.push ({name: 'Signature', query: { p: partnerNum }})
            }
        } else {
            this.$cookies.set('lifeplus_partner', this.$route.query.p, '6m')
        }

        if (this.$cookies.isKey('lifeplus_cookie')) {
            this.cookieShow = false
        }
    },
    computed: {
        ...mapGetters({
            userData: 'user/getPartnerAccess'
        }),
    },
    methods: {
        ...mapActions({
            addNotification: 'application/addNotification',
            showPartnerAccess: 'user/showPartnerAccess',
        }),
        cookieAccept() {
            this.cookieShow = false
            this.$cookies.set('lifeplus_cookie', 'accept', '12m')
        },
    },
    mounted () {
        this.showPartnerAccess(this.$route.query.p)
    },
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: raleway-b;
  src: url("/fonts/raleway-black.woff2");
  font-weight: 300;
}
.contact-avatar {
  top:-30px;
  bottom:0;
}
.page-box-wrapper {
  background: linear-gradient(to right, #dfdecd, #eddfc8);
  padding: 10px;
}
.page-box-content {
  background-color: #fff;
  padding: 10px 5px 20px;
  border-radius: 3px;
}
.h2-green {
  font-family: raleway-b;
  font-size: calc(24px + (36 - 24) * ((100vw - 320px) / (800 - 320)));
  text-transform: uppercase;
  text-align: center;
  color: #868686;
}
.h3-main {
  font-family: raleway-b;
  font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (800 - 320)));
  text-transform: uppercase;
  text-align: center;
  color: #5e5e5e;
}
.img-icon {
  width: 80px;
}
.box-border-wrapper {
  background: linear-gradient(to right, #dfdecd, #eddfc8);
  padding: 2px;
  border-radius: 5px;
}
.box-border-content {
  background-color: #fff;
  border-radius: 3px;
  padding: 10px;
}
.img-top-box {
  margin: 20px 0;
}
.germany-top {
  position: absolute;
  width: calc(40px + (70 - 40) * ((100vw - 320px) / (800 - 320)));
  right: 5%;
  top: 80px;
}
.sig-main {
  // max-width: 700px;
  width: 100%;
}
.p-main {
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 0 15px;
}
.h2-title-bg {
  text-align: center;
  font-family: raleway-b;
  color: #fff;
  text-transform: uppercase;
  background: linear-gradient(to right, #dfdecd, #eddfc8);
  border-radius: 4px;
}
.h3-col-title {
  text-align: center;
  text-transform: uppercase;
  margin-top: 5px;
}
.p-col {
  text-align: center;
  margin-bottom: 20px;
}
.oil-sig {
  max-width: 400px;
  width: 100%;
}
.face-avatar {
  border-radius: 50%;
  width: 100%;
  max-width: 320px;
}
.face-avatar-abs {
  border-radius: 50%;
  width: 100%;
  max-width: 300px;
}
.col-img {
  width: 80%;
  max-width: 200px;
}
.col-img-v {
  width: 80%;
  max-width: 100px;
}
.col-img-vs {
  width: 80%;
  max-width: 50px;
}
.ul-col {
  margin-left: 20px;
}
.ul-col-w {
  list-style-type: none;
}
.ul-txt {
  list-style-type: disc;
  margin-left: 30px;

}
.h4-title {
  text-align: center;
  font-family: raleway-b;
  color: #94b894;
  text-transform: uppercase;
}
.h4-green {
  color: #94b894;
}
.col-img-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-center {
  align-self: center;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}
.img-avatar {
  width: 80%;
  border-radius: 50%;
  border: 3px solid #94b894;
}
.h3-contacts {
  text-transform: uppercase;
  color: #333;
}
@media (min-width: 800px) {
  .h2-green {
    font-size: 36px;
  }
  .germany-top {
    width: 70px;
  }
}
@media (max-width: 680px) {  
  .img-avatar {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .h2-title-bg {
    font-size: 18px;
  }
  .col-img-center {
    padding: 0;
  }
  .col-center {
    font-size: 16px;
  }
  .img-icon-avatar {
    width: 60px;
    height: 60px;
  }
  .img-avatar {
    width: 40%;
  }
}
@media (max-width: 480px) {
  
}
@media (max-width: 430px) {
  
  .col-center {
    font-size: 14px;
  }
}
@media (max-width: 360px) {
  
}
</style>